import Vue from 'vue'
Vue.mixin({
    data() {
        return {
          addinUrl: process.env.VUE_APP_ADDIN_URL
        };
    },
    methods: {
      IsMessage(type) {
        if (
          type == "message" ||
          type == "response" ||
          type == "creator-response" ||
          type == "link-response" ||
          type == "creator-link-response" ||
          type == "fax-message" ||
          type == "sdk-message"
        ) {
          return true;
        } else {
          return false;
        }
      },
      validatePhone(phone)
      {
          let reg_phone = /^\+?[1-9]\d{4,14}$/; //eslint-disable-line
          return reg_phone.test(phone);
      },
      validateEmail(email)
      {
          let reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          return reg_email.test(email);
      },
      validatePNum(sPNum) 
      {
        sPNum = sPNum.replace("-","");
        var numbers = sPNum.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
        var checkSum = 0;
        if (!this.isDate(sPNum.substring(0,4),sPNum.substring(4,6),sPNum.substring(6,8))) {
          return false;
        }
        if (numbers == null) { return false; }
        var n;
        for (var i = 3; i <= 12; i++)
        {
          n=parseInt(numbers[i]);
          if (i % 2 == 0) {
            checkSum+=n;
          } else {
            checkSum+=(n*2)%9+Math.floor(n/9)*9
          }
        }
        if (checkSum%10==0) { return true;}
        return false;
      },
      bytesToSize(bytes, decimals = 2) {
          if (bytes === 0) return "0";
          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
          const i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      },
      getYear(y) { 
        return (y < 1000) ? y + 1900 : y;
      },
      isDate(year, month, day)
      {
        month = month - 1;
        var tmpDate = new Date(year,month,day);
        if ( (this.getYear(tmpDate.getYear()) == year) &&
        (month == tmpDate.getMonth()) &&
        (day == tmpDate.getDate()) )
          return true;
        else
          return false;
      },
      checkValidVersion(version) {
        var compare = this.$compare(this.user.version, version);
        return  (compare == 0 || compare == 1);
      },
      FormatDateTime(datetime)
      {
        return this.$luxonDateTime.fromISO(datetime).toLocaleString(this.$luxonDateTime.DATETIME_MED)
      },
      FormatDateTimeRelative(datetime)
      {
        return this.$luxonDateTime.fromISO(datetime).toRelative()
      }
    },
    computed: {
      sefos_locale(){
        return this.$i18n.locale;
      },
      locales(){
        return this.$store.state.lang.locales;
      },
      user(){
        return this.$store.state.user;
      },     
      teams() {
        return this.$store.state.teams;
      }
    }
  })