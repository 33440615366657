

export const ExternalSystemParticipantType = Object.freeze(
    {
        SDK: 0,
        FAX: 1,
        API: 2
    });
    
   
    export const MessageStatus = Object.freeze(
        {
            CREATED: "CREATED",
            READY_TO_SEND: "READY_TO_SEND",
            SEND_ENQUEUED: "SEND_ENQUEUED",
            WAITING_FOR_RETRY: "WAITING_FOR_RETRY",
            SEND_IN_PROGRESS: "SEND_IN_PROGRESS",
            SEND_ATTEMPT_FAILED: "SEND_ATTEMPT_FAILED",
            SEND_FAILURE: "SEND_FAILURE",
            SEND_SUCCESS: "SEND_SUCCESS",
            WAITING_FOR_RECEIPT: "WAITING_FOR_RECEIPT",
            ACKNOWLEDGED: "ACKNOWLEDGED",
            ACKNOWLEDGED_WITH_WARNING: "ACKNOWLEDGED_WITH_WARNING",
            NOTIFIED_RECIPIENT: "NOTIFIED_RECIPIENT",
            RECEIVED: "RECEIVED",
            RECEIVED_WITH_WARNINGS: "RECEIVED_WITH_WARNINGS",
            DOWNLOADED: "DOWNLOADED",
            DELETED: "DELETED",
            NOT_FOUND: "NOT_FOUND",
            UNKNOWN: "UNKNOWN",
            SENT_WITHOUT_NOTIFICATION: "SENT_WITHOUT_NOTIFICATION",
            RESPONDED: "RESPONDED",
            RECALLED: "RECALLED",
            NOT_VIEWED: "NOT_VIEWED",
            VIEWED: "VIEWED",
            ARCHIVED: "ARCHIVED",
            RECEIPT_TIMEOUT: "RECEIPT_TIMEOUT",
            ACCEPTED: "ACCEPTED",
            REJECTED: "REJECTED",
            ABORTED: "ABORTED"
        });


export class FileIcons {
    static GetByName(fileName)
    {
        var ext =  fileName.split('.').pop();
        return this.GetByExtension(ext);
    }
    static GetByMime(mimeType){
        switch(mimeType)
        {
            case "image/gif": return "fal fa-file-image";
            case "image/jpeg": return "fal fa-file-image";
            case "image/png": return "fal fa-file-image";
            case "application/pdf": return "fal fa-file-pdf";
            case "application/msword":  return "fal fa-file-word";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":  return "fal fa-file-word";
            case "application/mspowerpoint": return "fal fa-file-powerpoint";
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation": return "fal fa-file-powerpoint";
            case "application/msexcel": return "fal fa-file-excel";
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": return "fal fa-file-excel";
            case "text/csv": return "fal fa-file-csv";
            case "audio/aac": return "fal fa-file-audio";
            case "audio/wav": return "fal fa-file-audio";
            case "audio/mpeg": return "fal fa-file-audio";
            case "audio/mp4": return "fal fa-file-audio";
            case "audio/ogg": return "fal fa-file-audio";
            case "video/x-msvideo": return "fal fa-file-video";
            case "video/mpeg": return "fal fa-file-video";
            case "video/mp4": return "fal fa-file-video";
            case "video/ogg": return "fal fa-file-video";
            case "video/quicktime": return "fal fa-file-video";
            case "video/webm": return "fal fa-file-video";
            case "application/gzip": return "fal fa-file-archive";
            case "application/zip": return "fal fa-file-archive";
            case "text/css": return "fal fa-file-code";
            case "text/html": return "fal fa-file-code";
            case "text/javascript": return "fal fa-file-code";
            case "application/javascript": return "fal fa-file-code";
            case "text/plain": return "fal fa-file-alt";
            case "text/richtext": return "fal fa-file-alt";
            case "text/rtf": return "fal fa-file-alt";
        }
    }
    static GetByExtension(id) {
        switch(id)
        {
            case "gif": return "fal fa-file-image";
            case "jpeg": return "fal fa-file-image";
            case "jpg": return "fal fa-file-image";
            case "png": return "fal fa-file-image";
            case "pdf": return "fal fa-file-pdf";
            case "doc": return "fal fa-file-word";
            case "docx": return "fal fa-file-word";
            case "ppt": return "fal fa-file-powerpoint";
            case "pptx": return "fal fa-file-powerpoint";
            case "xls": return "fal fa-file-excel";
            case "xlsx": return "fal fa-file-excel";
            case "csv": return "fal fa-file-csv";
            case "aac": return "fal fa-file-audio";
            case "mp3": return "fal fa-file-audio";
            case "ogg": return "fal fa-file-audio";
            case "avi": return "fal fa-file-video";
            case "flv": return "fal fa-file-video";
            case "mkv": return "fal fa-file-video";
            case "mp4": return "fal fa-file-video";
            case "gz": return "fal fa-file-archive";
            case "zip": return "fal fa-file-archive";
            case "css": return "fal fa-file-code";
            case "html": return "fal fa-file-code";
            case "js": return "fal fa-file-code";
            case "txt": return "fal fa-file-alt"
       }  	
    }
}