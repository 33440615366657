export default {
  data: function () {
    return {
    }
  },
  created: function () {    
  },
  methods: {
    setEmailBody: async function (data, type = "html") {
      this.$store.dispatch("logger/add","setEmailBody");                          
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.body.setAsync(
          data,
          {
            "coercionType": type
          },
          function (asyncResult) {
            resolve(asyncResult.value);
          });
      });
    },
    setEmailSubject: async function (data) {
      this.$store.dispatch("logger/add","setEmailSubject");                          
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.subject.setAsync(
          data,
          function (asyncResult) {
            resolve(asyncResult.value);
          });
      });
    },
    getEmailAttachments: async function()
    {
      let attachmentList = await this.getEmailAttachmentList();
      return attachmentList;
    },
    getEmailAttachmentList: async function()
    {
      let self = this;
      return new Promise((resolve) => {
          self.$Office.context.mailbox.item.getAttachmentsAsync(
              function (asyncResult) {
                if (asyncResult.status === "succeeded") {
                  resolve(asyncResult.value);
                } else {
                  resolve([]);
                }
              }
          );
      });
    },
    getEmailAttachmentContent: async function (attachmentId, attachmentType) {
      let self = this;
      let options = { asyncContext: { type: attachmentType } };
      return new Promise((resolve) => {
          self.$Office.context.mailbox.item.getAttachmentContentAsync(attachmentId,
            options, function (asyncResult) {
                if (asyncResult.status === "succeeded") {
                    switch (asyncResult.value.format) {
                      case self.$Office.MailboxEnums.AttachmentContentFormat.Base64:
                        resolve(asyncResult.value.content);
                        break;
                      case self.$Office.MailboxEnums.AttachmentContentFormat.Eml:
                          break;
                      case self.$Office.MailboxEnums.AttachmentContentFormat.ICalendar:
                          break;
                      case self.$Office.MailboxEnums.AttachmentContentFormat.Url:
                          break;
                      default:
                    }
                  } else {
                      resolve(null);
                  }
              }
          );
      });
    },
    getEmailTo: async function () {
      let self = this;
      return new Promise((resolve) => {
          self.$Office.context.mailbox.item.to.getAsync(
              function (asyncResult) {
                  if (asyncResult.status === "succeeded") {
                      resolve(asyncResult.value);
                  } else {
                      resolve([]);
                  }
              }
          );
      });
    },
    getEmailCc: async function () {
      let self = this;
      return new Promise((resolve) => {
          self.$Office.context.mailbox.item.cc.getAsync(
              function (asyncResult) {
                  if (asyncResult.status === "succeeded") {
                      resolve(asyncResult.value);
                  } else {
                      resolve([]);
                  }
              }
          );
      });
    },
    getEmailBcc: async function () {
      let self = this;
      return new Promise((resolve) => {
          self.$Office.context.mailbox.item.bcc.getAsync(
              function (asyncResult) {
                  if (asyncResult.status === "succeeded") {
                    resolve(asyncResult.value);
                  } else {
                    resolve([]);
                  }
              }
          );
      });
    }
  }
};