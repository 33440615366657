export default {
  data: function () {
    return {
    }
  },
  created: function () {
    this.$store.dispatch("logger/add","init restMethods");
  },
  computed: {
  },
  methods: {
    restGetMessageInformation: async function(mailItemId) {
      this.$store.dispatch("logger/add","restGetMessageInformation");
      try {
        let apiurl = await this.getRestUrl(mailItemId);          
        let result = await this.$http.get(apiurl + "?$select=Id,Body,Subject,ToRecipients,CcRecipients,BccRecipients,HasAttachments", {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        }); 
        return result.data;
      } catch (error) {
        this.$store.dispatch("logger/add","restGetMessageInformation error");
        return null;
      }        
    },    
    restGetEmail: async function() {
      this.$store.dispatch("logger/add","restGetEmail");
      await this.getTokenBearer();
      try {
        let apiurl = await this.getRestUrl();
        let result = await this.$http.get(apiurl, {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        return result.data.EmailAddress;
      } catch (error) {
        this.$store.dispatch("logger/add","restGetEmail error");
        return null;
      }        
    },
    restGetAttachments: async function(mailItemId) {
      this.$store.dispatch("logger/add","restGetAttachments");
      await this.getTokenBearer();
      let attachments = [];
      try {
        let apiurl = await this.getRestUrl(mailItemId);
        this.$store.dispatch("logger/add","restGetAttachments url" + apiurl);
        let result = await this.$http.get(apiurl + "/attachments?$select=Name,Size,ContentType,IsInline", {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        for(let ix = 0; ix < result.data.value.length; ix++)
        {
          let attachment = result.data.value[ix];
          console.log(attachment);
          if(attachment.IsInline == false)
          {
            attachments.push({
              Id: attachment.Id,
              Name: attachment.Name,
              Size: attachment.Size
            });
          }          
        }
        return attachments;
      } catch (error) {
        this.$store.dispatch("logger/add","restGetAttachments error");
        return null;
      }        
    },
    restGetInlineAttachments: async function(mailItemId) {
      this.$store.dispatch("logger/add","restGetAllAttachments");
      await this.getTokenBearer();
      let attachments = [];
      try {
        let apiurl = await this.getRestUrl(mailItemId);
        this.$store.dispatch("logger/add","restGetAttachments url" + apiurl);
        let result = await this.$http.get(apiurl + "/attachments?$select=Name,Size,ContentType,IsInline", {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        for(let ix = 0; ix < result.data.value.length; ix++)
        {
          let attachment = result.data.value[ix];
          if(attachment.IsInline)
          {
            attachments.push(attachment);
          }
        }
        return attachments;
      } catch (error) {
        this.$store.dispatch("logger/add","restGetAttachments error");
        return null;
      }        
    },
    restGetAttachment: async function(mailItemId,attachmentId) {
      this.$store.dispatch("logger/add","restGetAttachment");
      try {
        let apiurl = await this.getRestUrl(mailItemId);
        let result = await this.$http.get(
          apiurl + "/attachments/" + attachmentId,
          {
            withCredentials: false,
            headers: { Authorization: "Bearer " + this.token_bearer }
          }
        );
        return result.data;
      } catch (error) {
        this.$store.dispatch("logger/add","restGetAttachment error");
        return [];
      }  
    },
    restMoveMail: async function (mailItem, folder) {
      this.$store.dispatch("logger/add","restMoveMail");  
      try {
        let apiurl = await this.getRestUrl(mailItem);
        let result = await this.$http.post(
          apiurl + "/move",
          {
            DestinationId: folder
          },
          {
            withCredentials: false,
            headers: { Authorization: "Bearer " + this.token_bearer }
          }
        );
        return result.data.Id;
      } catch (error) {
        this.$store.dispatch("logger/add","restMoveMail error " + folder);
        return "";
      }   
    },
    restDraftToSent: async function(mailItemId) {
      let self = this;
      self.$store.dispatch("logger/add","restDraftToSent");
      setTimeout(async function(){
        console.log("restDraftToSent setTimeout");
        self.$store.dispatch("logger/add","restDraftToSent setTimeout");
        try {
          let apiurl = await self.getRestUrl(mailItemId);  
          let result = await self.$http.get(apiurl, {
            withCredentials: false,
            headers: { Authorization: "Bearer " + self.token_bearer }
          }); 
          let message = result.data;
          self.$store.dispatch("logger/add",message);
          message['SingleValueExtendedProperties'] = [(
            {
              PropertyId: 'Integer 0x0E07',
              Value: '1'
            }
          )];
          self.$store.dispatch("logger/add",message);
          apiurl = await self.getRestUrl();
          result = await self.$http.post(apiurl + "/MailFolders/sentitems/messages/", message,
           {
            withCredentials: false,
            headers: { Authorization: "Bearer " + self.token_bearer }
          });          
          self.$store.dispatch("logger/add",result);
          //await self.restMoveMail(mailItemId,"deletedItems");
          result = await self.$http.delete(apiurl, null, {
            withCredentials: false,
            headers: { Authorization: "Bearer " + this.token_bearer }
          }); 
          self.$store.dispatch("logger/add",result);
          return result.data;
        } catch (error) {
          self.$store.dispatch("logger/add","restDraftToSent error" + error.message);
          return null;
        }     
       }, 3000);      
    }
  }
};