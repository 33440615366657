import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import VueCookies from 'vue-cookies'
Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  modules: {
    user: {
        namespaced: true,
        state: () => ({
            connection: true,
            information: null,
            not_registered: false, 
            hostname: '',
            email: '',
            token: '',
            version: '',
            uui_method: 'password',
            functionboxes: null,
            external_loa_levels: null,
            services: null,
            uui_extra: '',
            loa_level_enabled: null,
            message_response_default: 1,
            message_response_enabled: 1,
            email_information_text: '',
            email_edit_information_text: 1
        }),
        getters: {
            services: state => state.services,
            connection: state => state.connection,
            information: state => state.information,
            hostname: state => state.hostname,
            not_registered: state => state.not_registered,
            email: state => state.email,                
            external_loa_levels: state => state.external_loa_levels,
            token: state => state.token,
            version: state => state.version,
            functionboxes: state => state.functionboxes,
            loa_level_texts: state => state.loa_level_texts,
            loa_level_enabled: state => state.loa_level_enabled,
            message_response_default: state => state.message_response_default,
            message_response_enabled: state => state.message_response_enabled,
            email_edit_information_text: state => state.email_edit_information_text,
            email_information_text: state => state.email_information_text
        },
        mutations: {
            ["USER_SET_INFORMATION"](state, data) {
                state.information = data.user;                   
               
                if(data.external_loa_levels != undefined)
                {
                    state.external_loa_levels = data.external_loa_levels;
                }else{
                    if(data.max_loa != undefined){
                        state.external_loa_levels = {
                            loa1: data.max_loa.external >= 1 ? 1 : 0,
                            loa2: data.max_loa.external >= 2 ? 1 : 0,
                            loa3: data.max_loa.external == 3 ? 1 : 0
                        };
                    }else{
                        state.external_loa_levels = {
                            loa1: 1,
                            loa2: 1,
                            loa3: 1
                        }
                    }
                }
                if(data.loa_level_enabled != undefined)
                {
                    state.loa_level_enabled = data.loa_level_enabled;
                }else{
                    state.loa_level_enabled = {
                        loa1_enabled: 1,
                        loa2_enabled: 1,
                        loa3_enabled: 1
                    }
                }
                if(data.functionboxes != undefined){
                    state.functionboxes = data.functionboxes;
                }
                if(data.services != undefined){
                    state.services = data.services;
                }
                if(data.loa_level_texts != undefined){
                    state.loa_level_texts = data.loa_level_texts;
                }
                if(data.message_response_default != undefined){
                    state.message_response_default = data.message_response_default;
                }
                if(data.message_response_enabled != undefined){
                    state.message_response_enabled = data.message_response_enabled;
                }
                if(data.email_information_text != undefined){
                    state.email_information_text = data.email_information_text;
                }
                if(data.email_edit_information_text != undefined){
                    state.email_edit_information_text = data.email_edit_information_text;
                }
            },
            ["USER_SET_CONNECTION"](state, data) {
                state.connection = data;
            },
            ["USER_SET_HOSTNAME"](state, data) {
                state.hostname = data;
            },
            ["USER_SET_TOKEN"](state, data) {
                state.token = data;
            },
            ["USER_SET_EMAIL"](state, data) {
                state.email = data;
            },
            ["USER_SET_VERSION"](state, data) {
                state.version = data;
            },
            ["USER_CLEAR_INFORMATION"](state) {
                state.information = null;
                state.token = '';
            },
            ["FETCH_HOSTNAME_FAILURE"](state) {
                state.notregistered = true;
                state.information = null;
            },
            ["FETCH_USER_FAILURE"](state) {
                state.information = null;
            }
        },
        actions: {
            noConnection({ commit }) {
                commit("USER_SET_CONNECTION", false);
            },
            async setEmail({ commit }, email) {
                this.dispatch('logger/add',"STORE setEmail");     
                if(process.env.VUE_APP_EMAIL !== "")
                {
                    this.dispatch('logger/add',"STORE setEmail " + process.env.VUE_APP_EMAIL);  
                    commit("USER_SET_EMAIL", process.env.VUE_APP_EMAIL);
                }else{
                    this.dispatch('logger/add',"STORE setEmail " + email);  
                    commit("USER_SET_EMAIL", email);
                }    
            },     
            async fetchHostname({ commit, state }) {
                this.dispatch('logger/add',"STORE fetchHostname");                
                if(process.env.VUE_APP_SERVER !== "")
                {
                    commit("USER_SET_HOSTNAME", process.env.VUE_APP_SERVER);
                    this.dispatch('logger/add',"STORE fetchHostname " + process.env.VUE_APP_SERVER);  
                }else{
                    if( (state.hostname == '') && (state.email != ""))
                    {
                        try {
                            const result = await axios.post(process.env.VUE_APP_GLOBAL_URL + "/email/whereami",{
                                email: state.email
                            });
                            this.dispatch('logger/add',"STORE fetchHostname " + result.data.hostname);
                            commit("USER_SET_HOSTNAME", result.data.hostname + "/server/rest");
                        } catch (e) {
                            this.dispatch('logger/add',"STORE fetchHostname error");   
                            commit("FETCH_HOSTNAME_FAILURE", '');
                        }
                    }
                }
            }, 
            async fetchUser({ commit,state}) {
                if ( (state.email != "") && (state.hostname != "") ) {
                    try {
                        let result = await axios.get(state.hostname + "/isLoggedIn");
                        commit("USER_SET_INFORMATION", result.data);
                        let version = await axios.get(state.hostname + "/version");
                        commit("USER_SET_VERSION", version.data);
                    } catch (e) {
                        console.log(e);
                        commit("FETCH_USER_FAILURE", '');
                    }
                }
            },
            clearUserInformation({ commit }) { 
                Vue.$cookies.set("token", "", "1s", '', '', true, 'None');
                commit("USER_CLEAR_INFORMATION", null);
            }
        }
    },
    lang: {
        namespaced: true,
        state: () => ({
            locale: 'sv',
            locales: {
                'en': 'English'
                , 'sv': 'Svenska'
            }
        }),
        getters: {
            locale: state => state.locale,
            locales: state => state.locales,
            showAuth: state => state.auth
        },
        mutations: {
            ['LOCALE_SET'](state, { locale }) {
                state.locale = locale
            }
        },
        actions: {    
            setLocale({ commit }, locale) {
                commit('LOCALE_SET', { locale });
            }
    
        }
    },
    logger: {
        namespaced: true,
        state: () => ({
            log: ''
        }),
        getters: {
            log: state => state.log
        },
        mutations: {
            ['ADD_LOG'](state, { text }) {
                var d = new Date().toISOString().slice(0, 20).replace("T", " ");
                state.log = d + " " + text + "<br>" + state.log;
                console.log(d + " " + text);
            }
        },
        actions: {    
            add({ commit }, text) {
                commit('ADD_LOG', { text });
            }
    
        }
    },
    errors: {
        namespaced: true,
        state: ({
            validations: []
        }),
        getters: {
            validations: state => state.validations
        },
        mutations: {
            ['ADD_VALIDATIONS'](state, { validations }) {
                state.validations = validations.concat(validations)
            },
            ['REMOVE_VALIDATIONS'](state) {
                state.validations = []
            }
        },
        actions: {
            addValidations({ commit }, validations) {
                commit('ADD_VALIDATIONS', { validations });
            },
            removeValidations({ commit }) {
                commit('REMOVE_VALIDATIONS');
            }
        }
    }
  }
});